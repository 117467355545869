import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Head from "./Head";
import {
  getHome_Service_Data,
  getHome_upgrade_Data,
  gethome_brands_data
} from "../api/api";
import HomeBannerSlider from "../components/bannerslider";
import HomeCarousel from "../components/homecarousel";
import HomeCarouselMobile from "../components/homecarouselmobile";
import { imagePath } from "../utils/assetUtils";
import ClientTestimonials from "../components/client-testimonials";
import "./home.scss";
// import { motion } from "framer-motion";
import PageAnimWrapper from "../components/pagetransition";
import { FaAngleRight } from "react-icons/fa";

const Home = () => {
  const [ourServiceData, setOurServiceData] = useState([]);
  const [upgradeData, setUpgradeData] = useState([]);
  const [brandTrustLogo, setBrandTrustLogo] = useState([]);
  useEffect(() => {
    getHome_Service_Data().then(homeservice => {
      setOurServiceData(homeservice);
    }, []);

    getHome_upgrade_Data().then(upgradeitem => {
      setUpgradeData(upgradeitem);
    }, []);

    gethome_brands_data().then(brandlogos => {
      setBrandTrustLogo(brandlogos);
    }, []);
  }, []);
  return (
    <PageAnimWrapper>
      <div className="home-page-wrapper">
        <Head
          title="Makemelive Technologies – A complete web solution for all your business needs."
          ogdescription="The world is going digital, why not you? Expert Join us and groove into the world of possibilities! 01 Featured, Website 02 Featured, Website 03 Featured, Website 04 Featured, Web APP/Android Application 05 Featured, Website 06 Featured, Website 07 Featured"
          description="Website Design and Development Company"
          url="https://makemelive.in/"
          type="website"
          site_name="Makemelive Technologies"
        />
        {/* section Banner start */}
        <section className="fold1">
          <div className="slide-upper-title">
            <h4>The world is going digital, why not you?</h4>
          </div>
          <HomeBannerSlider />
          <div className="slide-bottom-title">
            <h3>Join us and groove into the world of possibilities!</h3>
          </div>
        </section>
        {/* section Banner end */}

        {/* section Home Carousel start */}
        <section className="fold2 fluid-container">
          <HomeCarousel />
          <HomeCarouselMobile />
        </section>
        {/* section Home Carousel end */}

        {/* section we are digital marketing start */}
        <section className="fold3">
          <div className="container">
            <div className="row justify-content-center no-gutters">
              <div className="home-portfolio-title-wrapper">
                <div className="home-portfolio-title">
                  <h3>We’ve Got More</h3>
                </div>
                <div className="home-portfolio-subtitle">
                  <Link to="/work/">
                    Take a look at our portfolio
                    <span className="angle-right">
                      <FaAngleRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row justify-content-center we-are-digital-marketing-wrapper">
              <div className="col-md-6 col-xs-12">
                <div
                  className="we-are-digital-marketing-leftside-wrap"
                  style={{
                    backgroundImage: `url(${imagePath("dot-dark.svg")})`
                  }}
                >
                  <div className="we-are-digital-marketing-title">
                    <h1>WE ARE A DIGITAL MARKETING AGENCY IN MUMBAI</h1>
                  </div>
                  <div className="we-are-digital-marketing-desc">
                    <p>
                      Looking for a Digital marketing agency in Mumbai to grow
                      your business with proficient Web developers in Mumbai?
                      Your quest ends here!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="we-are-digital-marketing-rightside-wrap">
                  <div className="we-are-digital-marketing-rightdesc para1">
                    <p>
                      Makemelive Technologies is a Top digital marketing agency
                      in Mumbai dedicated to delivering precise result oriented
                      Digital Marketing services in Mumbai. We at Makemelive
                      Technologies comprehend your business essentials,
                      tendencies, delineations, and planning. With outstanding
                      working experience of more than 15 years in this industry,
                      we are here to help you envisage your stature in the
                      digital world and advance your business to expand and
                      thrive online.
                    </p>
                  </div>
                  <div className="we-are-digital-marketing-rightdesc para2">
                    <p>
                      We ensure you to deliver incomparable web development
                      solutions based on your requirements. We solely pool the
                      power of innovation, novel technology, target-oriented
                      marketing, digital marketing services, and outcome metrics
                      to create unique digital makeovers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section we are digital marketing end */}

        {/* section do what is great banner start */}
        <section className="fold4">
          <div
            className="do-whatis-great-banner"
            style={{
              backgroundImage: `url(${imagePath("do-what-great-banner.jpeg")})`
            }}
          ></div>
        </section>
        {/* section do what is great banner end */}

        {/* section our services start */}
        <section className="fold5">
          <div className="container">
            <div className="our-services-title-desc-wrap">
              <div className="our-services-title">
                <h2>OUR SERVICES</h2>
              </div>
              <div className="our-services-desc">
                <p>
                  For us, digital marketing services aren’t just about consumer
                  involvement; we focus more on delivering our clients something
                  exceptional to look at. Our digital experts work day and night
                  to create more innovative, ingenious, and expressive
                  techniques to expand your business in the digital marketing
                  industry. We provide our superior core services for:
                </p>
              </div>
            </div>
            <div className="row justify-content-center no-gutters our-services-row-wrap">
              {ourServiceData.map(ourServiceData => (
                <div className="col-md-3 col-xs-12 web-design-col-wrap">
                  <div className="web-design-column">
                    <figure className="services-column-icon">
                      <img
                        src={
                          "https://api.mmlprojects.in/images/" +
                          ourServiceData.home_service_image
                        }
                        alt="websitedevelopment"
                      />
                    </figure>
                    <div className="our-services-contentwrap">
                      <div className="our-service-column-title">
                        <h3>{ourServiceData.home_service_title}</h3>
                      </div>
                      <div className="our-services-column-desc">
                        <p>{ourServiceData.home_service_description}</p>
                        <Link
                          to={ourServiceData.link}
                          className="our-services-learn-more"
                        >
                          Learn More
                          <span className="angle-right">
                            <FaAngleRight />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* section our services end */}

        {/* section upgrate start */}
        <section className="fold6">
          <div className="container">
            <div className="row justify-content-center no-gutters">
              <div className="col-md-6 col-xs-12">
                <div
                  className="upgrade-top-section-left-col"
                  style={{
                    backgroundImage: `url(${imagePath("dot-dark.svg")})`
                  }}
                >
                  <div className="upgrade-title">
                    <h2>UPGRADE</h2>
                  </div>
                  <div className="upgrade-desc">
                    <p>
                      Help your business evolve with our premium services!
                      Cutting-edge solutions that help you enhance your user
                      experience & cultivate brand loyalty.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="upgrade-top-section-right-col">
                  <div className="upgrad-img-wrap">
                    <div className="upgrade-right-sideimg">
                      <img
                        src={imagePath("upgrade-premium-bg.jpeg")}
                        alt="upgrade-premium"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center no-gutters upgrade-btm-section-wrap">
              {upgradeData.map(upgradeData => (
                <div className="col-md-4 col-xs-12">
                  <div className="upgrade-btm-section-column">
                    <div className="upgrade-btm-column-img">
                      <img
                        src={
                          "https://api.mmlprojects.in/images/" +
                          upgradeData.home_upgrade_image
                        }
                        alt="aws"
                      />
                    </div>
                    <div className="upgrade-btm-column-content-wrap">
                      <div className="upgrade-btm-col-title">
                        <h3>{upgradeData.home_upgrade_title}</h3>
                      </div>
                      <div className="upgrade-btm-col-desc">
                        <p>{upgradeData.home_upgrade_description}</p>
                        <Link
                          to={upgradeData.link}
                          className="our-services-learn-more"
                        >
                          Learn More
                          <span className="angle-right">
                            <FaAngleRight />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* section upgrate end */}

        {/* section we have dont it for brands big and small start */}
        <section className="fold7">
          <div className="container">
            <div className="row justify-content-center no-gutters v-align">
              <div className="col-md-6 col-xs-12">
                <div className="big-small-brand-leftside-wrap">
                  <div className="big-small-brand-title">
                    <h2>WE'VE DONE IT FOR BRANDS BIG AND SMALL</h2>
                  </div>
                  <div className="big-small-brand-desc">
                    <p>
                      Proven in the Digital Space for over 10+ years, more than
                      200+ customers already trust MAKEMELIVE TECHNOLOGIES. We
                      have created an incredible amount of over 500+ websites.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div
                  className="big-small-brand-rightside-wrap"
                  style={{
                    backgroundImage: `url(${imagePath("dot-dark.svg")})`
                  }}
                >
                  <img
                    className="happy-client-image"
                    src={imagePath("happy-clients.jpeg")}
                    alt="happyclients"
                  />
                </div>
              </div>
            </div>
            <div className="big-small-section-border"></div>
          </div>
        </section>
        {/* section we have dont it for brands big and small end */}

        {/* our awesome clients start */}
        <section className="fol8">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <div className="col-md-12 col-xs-12">
                  <div className="our-partners align-center">
                    <h3>Brands That Trust Us</h3>
                  </div>
                  <div className="partner-wrapper">
                    <div className="box-row row">
                      {brandTrustLogo &&
                        brandTrustLogo.map(brandTrustLogo => (
                          <div className="box-cell col-md-3 col-xs-12">
                            <img
                              src={
                                "https://api.mmlprojects.in/images/" +
                                brandTrustLogo.home_brands_image
                              }
                              alt="dentsu"
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* our awesome clients end */}
        {/* Client Testimonials start */}
        <section className="fold9">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-xs-12">
                <div className="client-testimonial-title">
                  <h2>Growing Clients.</h2>
                </div>
                <div className="client-testimonial-data">
                  <p>
                    Look at the success stories and experience of our clients at
                    Makemelive.
                  </p>
                </div>
                <ClientTestimonials />
              </div>
            </div>
          </div>
        </section>
        {/* Client Testimonials end */}
      </div>
    </PageAnimWrapper>
  );
};

export default Home;
