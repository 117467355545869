import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { gethome_testimonial_data } from "../../api/api";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "./client-testimonials.scss";

export default function ClientTestimonials() {
  const [clientTestimonial, setClientTestimonial] = useState([]);
  useEffect(() => {
    gethome_testimonial_data().then(clientdata => {
      setClientTestimonial(clientdata);
    }, []);
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow: <FaChevronRight />,
    prevArrow: <FaChevronLeft />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings} className="client-testimonials-wrapper">
      {clientTestimonial &&
        clientTestimonial.map(clientTestimonial => (
          <div className="main-testimonial-client-wrap">
            <div className="client-testimonials-item-wrap">
              <div className="client-testimonial-pic">
                <img
                  src={
                    "https://api.mmlprojects.in/images/client-testimonials/" +
                    clientTestimonial.home_testimonials_client_project_logo
                  }
                  alt="websitedevelopment"
                />
              </div>
              <div className="client-testimonials-quote">
                <div className="client-testimonials-desc">
                  <p>
                    {clientTestimonial.home_testimonials_client_testimonial}
                  </p>
                </div>
                <div className="testimonials-author">
                  <h3>{clientTestimonial.home_testimonials_client_name}</h3>
                </div>
                <div className="client-testimonial-origin">
                  <h4>
                    {clientTestimonial.home_testimonials_client_designation} ,{" "}
                    {clientTestimonial.home_testimonials_project_name}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
    </Slider>
  );
}
