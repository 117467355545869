import React, { useState, useEffect } from "react";
import Slider from "react-slick";
// import { homesliderImagePath } from "../../utils/assetUtils";
import { getHome_featured_carousel_Data } from "../../api/api";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "./homecarousel.scss";

export default function HomeCarousel() {
  const [homeFeaturedCarousel, setHomeFeaturedCarousel] = useState([]);
  useEffect(() => {
    getHome_featured_carousel_Data().then(carouselhome => {
      setHomeFeaturedCarousel(carouselhome);
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    centerMode: true,
    // focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <FaChevronRight />,
    prevArrow: <FaChevronLeft />
  };
  return (
    <Slider {...settings} className="home-carousel-wrap slick-center">
      {homeFeaturedCarousel &&
        homeFeaturedCarousel.map(homeFeaturedCarousel => (
          <div className="bg-txt-wrap">
            <div className="bg-txt">
              {homeFeaturedCarousel.home_featured_crousel_project_name}
            </div>
            <div className="slideitem">
              <figure className="slide-assets">
                <span className="home-carousel-number">
                  {homeFeaturedCarousel.position}
                </span>
                <div className="carousel-img">
                  <img
                    src={
                      "https://api.mmlprojects.in/images/home-slider/" +
                      homeFeaturedCarousel.home_featured_crousel_project_image
                    }
                    alt="edtimes"
                  />
                </div>
                <figcaption className="home-carousel-caption caption-font">
                  {homeFeaturedCarousel.home_featured_crousel_project_name}
                </figcaption>
              </figure>
            </div>
          </div>
        ))}
    </Slider>
  );
}
