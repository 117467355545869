import React, { useState, useEffect } from "react";
import Slider from "react-slick";
// import { homesliderImagePath } from "../../utils/assetUtils";
import { getHome_featured_carousel_Data } from "../../api/api";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "./homecarousel-mob.scss";

export default function HomeCarouselMobile() {
  const [homeFeaturedmobCarousel, setHomeFeaturedmobCarousel] = useState([]);
  useEffect(() => {
    getHome_featured_carousel_Data().then(carouselhome => {
      setHomeFeaturedmobCarousel(carouselhome);
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <FaChevronRight />,
    prevArrow: <FaChevronLeft />
  };
  return (
    <Slider {...settings} className="home-carousel-mob-wrap slick-center">
      {homeFeaturedmobCarousel &&
        homeFeaturedmobCarousel.map(homeFeaturedmobCarousel => (
          <div className="bg-txt-wrap">
            <div className="bg-txt">
              {homeFeaturedmobCarousel.home_featured_crousel_project_name}
            </div>
            <div className="slideitem">
              <figure className="slide-assets">
                <span className="home-carousel-number">
                  {homeFeaturedmobCarousel.id}
                </span>
                <div className="carousel-img">
                  <img
                    src={
                      "https://api.mmlprojects.in/images/home-slider/" +
                      homeFeaturedmobCarousel.home_featured_crousel_project_image
                    }
                    alt="xp-pen"
                  />
                </div>
                <figcaption className="home-carousel-caption caption-font">
                  {homeFeaturedmobCarousel.home_featured_crousel_project_name}
                </figcaption>
              </figure>
            </div>
          </div>
        ))}
    </Slider>
  );
}
